<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }} {{ $t('app.costo-horario.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.deportes.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.deportes.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.deporte_id"
                        :placeholder="$t('app.deportes.single')"
                        :items="deportes"
                        item-text="descripcion"
                        item-value="id"
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.general.day') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.general.day')"
                      rules="required"
                    >
                      <v-select
                        v-model="item.dia"
                        :placeholder="$t('app.general.day')"
                        :items="dias"
                        item-text="text"
                        item-value="id"
                        :error-messages="errors"
                        required
                      ></v-select>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.time') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="timePickerModal"
                      v-model="timePickerModal"
                      :return-value.sync="item.hora"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.time')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.hora"
                            :label="$t('app.headers.time')"
                            prepend-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-time-picker
                        v-if="timePickerModal"
                        v-model="item.hora"
                        full-width
                      >
                        <v-col class="d-flex justify-center">
                          <v-btn
                            text
                            color="primary"
                            @click="timePickerModal = false"
                          >
                            {{ $t('app.buttons.cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.timePickerModal.save(item.hora)"
                          >
                            {{ $t('app.buttons.ok') }}
                          </v-btn>
                        </v-col>
                      </v-time-picker>
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.price') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.price')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="item.costo"
                        :placeholder="$t('app.headers.price')"
                        :error-messages="errors"
                        required
                        prefix="$"
                        suffix="MXN"
                        type="number"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'CostoHorarioEdit',
    data () {
      return {
        dialog: false,
        item: {
          dia: null,
          hora: null,
          deporte_id: null,
          costo: null
        },
        timePickerModal: false,
        deportes: [],
        sucursales: [],
        datePickerModalStart: false,
        datePickerModalEnd: false,
        dias: [
          {
            id: 0,
            text: 'Lunes'
          },
          {
            id: 1,
            text: 'Martes'
          },
          {
            id: 2,
            text: 'Miércoles'
          },
          {
            id: 3,
            text: 'Jueves'
          },
          {
            id: 4,
            text: 'Viernes'
          },
          {
            id: 5,
            text: 'Sábado'
          },
          {
            id: 6,
            text: 'Domingo'
          },
        ],
      }
    },
    mounted () {
      EventBus.$on('costo-horario-edit', async (item) => {
        this.toggleLoader()
        this.sucursales = await this.getBranches()
        this.deportes = await this.getDeportes()
        this.item = item

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.post(route('v1/rate/' + this.item.id), this.item)
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
